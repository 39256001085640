import { ITestRun, ITestRunPage, TestResult, TestResultData, TestRun } from "../models/testRun"

export class TestRunAdapter{
    public fromDto(testRunDto: ITestRun): TestRun{
        const testRun = new TestRun(
            testRunDto.id,
            testRunDto.name,
            testRunDto.suite,
            testRunDto.state,
            testRunDto.startTime,
            testRunDto.endTime,
            testRunDto.expiryTime,
            testRunDto.testResults.map(res => new TestResult(
                res.id,
                res.state,
                res.testType,
                res.device,
                res.card,
                res.timeoutDuration,
                res.startTime,
                res.endTime,
                res.result,
                res.amount,
                res.currency,
                res.preCleanup,
                res.data.map(res => new TestResultData(
                    res.key,
                    res.value,
                    res.createdAt
                ))
            ))
        )
        return testRun
    }
}